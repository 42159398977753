

import anime from 'animejs';

export default class{
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {
        this.mask = document.querySelector('#js-loading__mask')
        this.first = "#js-loading__mask .js-loading__first"
        this.second = "#js-loading__mask .js-loading__second"
        this.logoAnimation_elm = document.querySelector('#logo-animation')
        if(this.mask){
            this.mask.style.display = "block"
        }

        if(process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development"){
            if(this.mask){
                this.mask.style.display = "none" 
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data){
        const self = this;
        return new Promise(resolve => {


            if(data.next.namespace == "not-loading"){
                resolve()
            }


            let timeline = anime.timeline();
            
            timeline
            .add({
                targets: '#logo-animation .logo-1',
                translateY : ["110%",0],
                easing: 'easeOutExpo',
                duration : 800
            },500)
            .add({
                targets: '#logo-animation .logo-1',
                translateY : [0,"-110%"],
                easing: 'easeInSine',
                duration : 400,
                complete: () =>{
                    this.page = document.querySelector("#page")
                    if(this.page){
                        this.page.style.opacity = "1"
                    }
                    resolve();
                }
            },1500)
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data){
        return new Promise(resolve => {
            this.page = document.querySelector("#page")
            if(this.page){
                this.page.style.opacity = "1"
            }

            if(data.next.namespace == "not-loading"){
                resolve();
            }


            // anime({
            //     targets: ".l-loading-steam__thirdly",
            //     scale:[.5,1.5],
            //     easing: 'linear',
            //     duration : 1000,
            //     loop:true
            // })
            
            this.initialScroll(true)
            const timelime = anime.timeline()
            timelime
            .add({
                targets: this.first ,
                translateY : [0,"-100%"],
                easing: 'easeInOutExpo',
                duration : 1000,
                complete: () =>{
                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    resolve();
                }
            })
            
        });
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data){

        return new Promise(resolve => {
            this.intro()
            resolve();
        })
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data){
        return new Promise(resolve => {
            
            data.next.container.style.opacity = 0;
            resolve();

        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data){
        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if(this.mask){
                this.mask.style.display = "block"
            }

            if(data.next.namespace == "not-loading"){
                resolve();
            }
            const timelime = anime.timeline()
            
            timelime.add({
                targets: data.current.container,
                opacity : [1,0],
                translateY: [0,"-100px"],
                easing: 'easeOutSine',
                duration : 500,
                complete : () => {

            data.next.container.style.opacity = 0;
                    data.current.container.style.display = "none"
                    resolve();
                }
            },800)
        });
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data){
        
        return new Promise(resolve => {
            resolve();
        })

    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data){
        return new Promise(resolve => {
            if(data.next.namespace == "not-loading"){
                data.next.container.style.opacity = 1;
                this.initialScroll (false)
                resolve();
            }
            
            const timelime = anime.timeline()
            timelime
            .add({
                targets: data.next.container,
                opacity : [0,1],
                translateY: ["100px",0],
                easing: 'easeOutExpo',
                duration : 1000,
                begin: () => {
                    this.initialScroll(false)
                }
            },300)
            .add({  
                targets: this.first ,
                easing: 'easeInOutExpo',
                duration : 200,
                complete : () => {
                    
                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    

                    resolve()
                }
            })

        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    initialScroll (isOnce){
            if( location.hash == "" ){
                window.scrollTo(0,0);
            }else{
                let digit = location.hash.replace(/^#/,"");
                let anchor = document.getElementById( digit );
                if ( !anchor ) return;
                
                if(anchor){
                    const rect = anchor.getBoundingClientRect();
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    let top = rect.top + scrollTop;
                    const header = document.querySelector('[data-header]');
                    if(header){
                        if(header){
                            top = top - header.clientHeight;
                        }
                    }
                    // if(window.innerWidth < 1024){
                    //     window.scrollTo(0,top);
                    // }else{
                    //     window.LocomotiveScroll.scrollTo(anchor)
                    // }
                    let offset = -160
                    if(isOnce){
                        offset = -60
                    }
                    window.LocomotiveScroll.scrollTo(anchor,{
                        offset:offset
                    })
                }
            }
        
    }
}