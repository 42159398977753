
import barba from '@barba/core';
import anime from 'animejs';
import MicroModal from 'micromodal';

export default class{

	constructor(){
        this.drawerLinks = []
        this.opened = false

        this.opening_class = 'drawer-opening'
        this.opened_class = 'drawer-opened'
        this.closing_class = 'drawer-closing'
        
        this.hamburger_id = "js-hamburger"
        this.drawer_id = "js-drawer"
        this.drawer_link_class = "[data-drawer-links]"
        this.drawer_str_class = "l-drawer__nav--str"

        barba.hooks.once((data) => {
            this.init()
        })
     
    }

    init(){
        this.body = document.body;
        this.hamburger = document.querySelector('#' +this.hamburger_id);
        this.drawer = document.querySelector('#' + this.drawer_id);
        if(this.drawer){
            this.drawerLinks = this.drawer.querySelectorAll(this.drawer_link_class);
            if(this.hamburger){
                this.hamburger.addEventListener('click',(e) => {
                    this.drawerClick(e);
                })
            }
            if(this.drawerLinks){
                if( this.drawerLinks.length > 0 ){
                    this.drawerLinks.forEach( (drawerLink) => {
                        this.drawerRemoveClass(drawerLink)
                    });
                }
            }
            
            this.timeline = anime.timeline({
                autoplay: false
            })

            this.timeline
            .add({
                targets: ".l-drawer-illust",
                easing:"easeOutQuart",
                duration:2000,
                opacity:[0,1],
            })
            // ユーザーエージェントを取得し小文字にする
            const userAgent = window.navigator.userAgent.toLowerCase();
            let  isSafari = false
            if (userAgent.indexOf("chrome") != -1) {
                isSafari = false
            } else if (userAgent.indexOf("safari") != -1) {
                isSafari = true
            }

            if(!isSafari){
                this.timeline
                .add({
                    targets: ".l-drawer-illust .drawer-img-place1",
                    translateY:["100%",0],
                    translateX:["-80%",0],
                    easing:"easeOutExpo",
                    duration:2000
                },"-=1500")
                .add({
                    targets: ".l-drawer-illust .drawer-img-car",
                    translateY:["100%",0],
                    translateX:["-80%",0],
                    easing:"easeOutExpo",
                    duration:2000
                },"-=2000")
                .add({
                    targets: ".l-drawer-illust .drawer-img-place2",
                    translateY:["100%",0],
                    translateX:["-80%",0],
                    easing:"easeOutExpo",
                    duration:2000
                },"-=1800")
                .add({
                    targets: ".l-drawer-illust .drawer-img-place3",
                    translateY:["100%",0],
                    translateX:["-80%",0],
                    easing:"easeOutExpo",
                    duration:2000
                },"-=1800")
                .add({
                    targets: ".l-drawer-illust .drawer-img-fish",
                    translateY:["30px",0],
                    translateX:["-30px",0],
                    opacity:[0,1],
                    delay:(el,i)=>{
                        return i * 200
                    },
                    easing:"easeOutExpo",
                },"-=1800")
                .add({
                    targets: ".l-drawer-illust .drawer-img-turtle",
                    translateY:["30px",0],
                    translateX:["-30px",0],
                    opacity:[0,1],
                    easing:"easeOutExpo",
                    duration:2000
                },"-=1800")
            
            }
            
        }
        
    }
    drawerClick(e){
            
        this.drawerToggleClass();
    }
    drawerToggleClass(){
        if(!this.drawer.classList.contains('is-open')){
            this.open();
        }else{
            this.close();
        }
    }
    drawerRemoveClass(drawerLink){
        drawerLink.addEventListener('click',() =>{
            this.close();
        })
    } 
    open(){
        
        this.timeline.seek(0);
        this.timeline.restart()
        MicroModal.show(this.drawer_id,{
            onShow (){
                document.querySelector("#js-drawer .modal__container").scrollTop = 0
                window.LocomotiveScroll.stop()

            }, // [1]
            onClose (){
                window.LocomotiveScroll.start()

                document.querySelector('#js-hamburger').setAttribute('aria-expanded',false)
            }, 
            // openTrigger: 'data-custom-open', // [3]
            // closeTrigger: 'data-custom-close', // [4]
            // openClass: 'is-open', // [5]
            disableScroll: true, // [6]
            disableFocus: true, // [7]
            awaitOpenAnimation: true, // [8]
            awaitCloseAnimation: true, // [9]
        })
        this.hamburger.setAttribute('aria-expanded',true)
        // anime({
        //     targets:this.drawer_str_class,
        //     translateY:['100%',0],
        //     easing: 'easeInOutSine',
        //     duration:500,
        //     delay:function(el,i){
        //         return i * 30;
        //     }
        // })
        // this.opened = true;

    }
    close (){
        // this.body.classList.remove(this.opening_class);
        // this.body.classList.add(this.closing_class);
        this.hamburger.setAttribute('aria-expanded',false)
        // setTimeout(() =>{
        //     this.drawer.scrollTop =  0;
        //     this.body.classList.remove(this.closing_class);
        //     this.body.classList.remove(this.opened_class);
        //     this.body.classList.remove(this.opening_class);
        //     this.opened = false;
        // },300)
        MicroModal.close(this.drawer_id)
    }
}