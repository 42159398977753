
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            // this.pickupSlider()
            // this.eventSlider()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            // this.pickupSlider()
            // this.eventSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('#p-index-hero__slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            }
        })
    }
    
    
    blogSlider(){
        const blog = new Swiper('.js-blog-slider', {
            speed:400,
            slidesPerView: 'auto',
            spaceBetween: 20,
            simulateTouch:true,
            centeredSlides: false,
            breakpoints: {
                
                576: {
                    slidesPerView: 'auto',
                    spaceBetween:  40,
                }
            },
            scrollbar: { 
                el: '.js-blog-slider .swiper-scrollbar',
            },
        })
    }
    caseSlider(){
        const caseSlider = new Swiper('.js-case-slider', {
            speed:400,
            slidesPerView: 'auto',
            spaceBetween: 20,
            simulateTouch:true,
            centeredSlides: false,
            breakpoints: {
                
                576: {
                    slidesPerView: 'auto',
                    spaceBetween:  40,
                }
            },
            scrollbar: { 
                el: '.js-case-slider .swiper-scrollbar',
            },
        })
    }
    
}