

import barba from '@barba/core';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import LocomotiveScroll from "locomotive-scroll";


export default class{

	constructor(){

        this.isInit = false
		this.initY = 0
        this.triggerIndexFeatures = false
        this.triggerIndexProductPrimary = false
        this.triggerIndexProductSecondary = false

        window.historyScrollPosition = []
        
        barba.hooks.once((data) => {
            this.run(document.querySelector("[data-scroll-container]"))
        })

        barba.hooks.afterOnce((data) => {
            this.refresh()
        })

        barba.hooks.beforeLeave((data) => {
            if(data.trigger == "back" || data.trigger == "forward"){
                if(window.historyScrollPosition.length > 0){
                    this.initY = window.historyScrollPosition.pop()
                }
            }
            window.historyScrollPosition.push(window.LocomotiveScroll.scroll.instance.scroll.y)

        })

        barba.hooks.leave((data) => {
            window.LocomotiveScroll.destroy()
        })
        
        barba.hooks.afterEnter((data) => {
           
            if(this.isInit){
                this.run(data.next.container.querySelector("[data-scroll-container]"))
                window.LocomotiveScroll.update()
                ScrollTrigger.refresh();
            }
            if(!this.isInit){
                this.isInit = true
            }
        })

        barba.hooks.after((data) => {
            window.LocomotiveScroll.update()
            ScrollTrigger.refresh();
            this.initY = 0
        });
    }
    
    run(el){
        gsap.registerPlugin(ScrollTrigger);

        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
        
        window.LocomotiveScroll = new LocomotiveScroll({
            el: el,
            smooth: true,
            lerp:.2,
            multiplier:.4,
            initPosition :{
                x: 0,
                y: this.initY
            }
        });
        
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        window.LocomotiveScroll.on("scroll", ScrollTrigger.update);

        // tell ScrollTrigger to use these proxy methods for the "[data-scroll-container]" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy("[data-scroll-container]", {
            scrollTop(value) {
                return arguments.length ? window.LocomotiveScroll.scrollTo(value, 0, 0) : window.LocomotiveScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
            pinType: document.querySelector("[data-scroll-container]").style.transform ? "transform" : "fixed"
        });

       
        // ScrollTrigger.matchMedia({
	
        //     "(min-width:992px)": () => {
            
               
        //     }
        // })

        // // --- RED PANEL ---
        // gsap.from(".line-1", {
        //     scrollTrigger: {
        //         trigger: ".line-1",
        //         scroller: "[data-scroll-container]",
        //         scrub: true,
        //         start: "top bottom",
        //         end: "top top",
        //         onUpdate: self => console.log(self.direction)
        //     },
        //     scaleX: 0,
        //     transformOrigin: "left center", 
        //     ease: "none"
        // });


        // // --- ORANGE PANEL ---
        // gsap.from(".line-2", {
        //     scrollTrigger: {
        //         trigger: ".orange",
        //         scroller: "[data-scroll-container]",
        //         scrub: true,
        //         pin: true,
        //         start: "top top",
        //         end: "+=100%"
        //     },
        //     scaleX: 0, 
        //     transformOrigin: "left center", 
        //     ease: "none"
        // });


        // var tl = gsap.timeline({
        //     scrollTrigger: {
        //     trigger: ".purple",
        //     scroller: "[data-scroll-container]",
        //     scrub: true,
        //     pin: true,
        //     start: "top top",
        //     end: "+=100%"
        //     }
        // });

        // tl.from(".purple p", {scale: 0.3, rotation:45, autoAlpha: 0, ease: "power2"})
        // .from(".line-3", {scaleX: 0, transformOrigin: "left center", ease: "none"}, 0)
        // .to(".purple", {backgroundColor: "#28a92b"}, 0);
    }
    
    refresh(){
       
        ScrollTrigger.addEventListener("refresh", () => {
            window.LocomotiveScroll.update()
        });
        ScrollTrigger.refresh();
    }

}