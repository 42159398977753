
import barba from '@barba/core';
import gsap from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
import anime from 'animejs';


export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            const index = data.next.container.querySelector('#p-index')
            if(index){
                this.leave()
            }
        })

        barba.hooks.afterOnce((data) => {
            const index = document.querySelector('#p-index')
            if(index){
                this.intro(data.next.container,true)
            }
        })


        barba.hooks.enter((data) => {
            const index = document.querySelector('#p-index')
            if(index){
                this.intro(data.next.container,false)
            }
        })
    }
    leave (){
        const header = document.querySelectorAll('#l-header')
        const gnav = document.querySelectorAll('#l-gnav')
        const hamburger = document.querySelectorAll('.l-hamburger')
        const floating = document.querySelectorAll('#l-floating')

        if(header.length > 0){
            header.forEach(e => { e.style.opacity = 0})
        }
        
        if(gnav.length > 0){
            gnav.forEach(e => { e.style.opacity = 0})
        }

        if(hamburger.length > 0){
            hamburger.forEach(e => { e.style.opacity = 0})
        }

        if(floating.length > 0){
            floating.forEach(e => { e.style.opacity = 0})
        }
        
    }

    intro(container,once){
        const header = document.querySelector('#l-header')
        const gnav = document.querySelector('#l-gnav')
        const hamburger = document.querySelector('.l-hamburger')
        const floating = document.querySelectorAll('#l-floating')


        const timelime = anime.timeline()
        timelime
        .add({  
            targets:container.querySelector("#p-index-hero__clip"),
            translateX : ["100%","6vw"],
            translateY : ["-100%","-8vh"],
            easing: 'easeOutExpo',
            duration :2500
        })
        .add({  
            targets:container.querySelector("#p-index-hero__clip-sp"),
            translateX : ["100%","-65vw"],
            translateY : ["-100%","-8vh"],
            scaleX : ["1","2.7"],
            scaleY: [1,.9],
            easing: 'easeInOutExpo',
            duration :2500
        },"-=2500")
        .add({  
            targets:container.querySelectorAll(".p-index-hero__ja .p-index-hero__ja-l1 span"),
            translateY : ["200%",0],
            skewY:["3deg",0],
            easing: 'easeOutExpo',
            duration : 1000
        },"-=1000")
        .add({
            targets:container.querySelectorAll(".p-index-hero__en .p-index-hero__en-l1 span"),
            translateY : ["200%",0],
            skewY:["3deg",0],
            easing: 'easeOutExpo',
            duration : 1000
        },"-=600")
        
        .add({  
            targets:container.querySelector(".p-index-hero__news"),
            translateY : ["30%",0],
            opacity:[0,1],
            easing: 'easeOutExpo',
            duration : 1200
        },"-=800")
        if(once){
            timelime.add({  
                targets:header,
                translateY : ["-30%",0],
                opacity:[0,1],
                easing: 'easeOutExpo',
                duration : 1200
            },"-=800")
            .add({  
                targets:gnav,
                translateY : ["-30%",0],
                opacity:[0,1],
                easing: 'easeOutExpo',
                duration : 1200
            },"-=1200")
            .add({  
                targets:hamburger,
                translateY : ["-30%",0],
                opacity:[0,1],
                easing: 'easeOutExpo',
                duration : 1200
            },"-=1200")
            .add({  
                targets:floating,
                opacity:[0,1],
                easing: 'easeOutExpo',
                duration : 1200
            },"-=1200")
        }
    }
}