

import barba from '@barba/core';
import anime from 'animejs';
import { random } from 'core-js/core/number';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            // this.trigger = new ScrollTrigger()
            // this.initailTrigger()
            this.init()
            this.headerInview()
        });
        // barba.hooks.leave((data) => {
        //     this.kill(data)
        // });
        barba.hooks.enter((data) => {
            if(data.trigger==='back' || data.trigger==='forward'){
                const sections = document.querySelectorAll('[data-scroll-trigger]')
                if( sections.length > 0 ){
                    sections.forEach( el => { 
                        el.classList.add('-visible')
                    })
                }
            }else{
                this.init()
            }

            this.headerInview()
            // this.trigger = new ScrollTrigger()
            // this.initailTrigger()
        });

    }
    init(){
        const callback = (entries) => {
            entries.forEach( (entry) => {
                
                if (entry.isIntersecting) {
                    visible(entry.target);
                }
            });
        }
        const observerOptions = {
            rootMargin: "0px 0px -20% 0px",
            threshold: 0
        }
        this.observer = new IntersectionObserver(callback, observerOptions)
        const sections = document.querySelectorAll('[data-scroll-trigger]')
        if( sections.length > 0 ){
            sections.forEach( el => { 
                this.observer.observe(el)
            })
        }

        // const strAnimations = document.querySelectorAll('[data-scroll-trigger="translateY"]')
        // if(strAnimations.length > 0 ){
        //     strAnimations.forEach((el) => {
        //         let _str = el.textContent.split("");
        //         let str = "";
        //         _str.map( s => {
        //             str += "<span class='str__animation'>" + s  + "</span>";
        //         })
                    
        //         el.innerHTML = str;
        //     })
        // }

        const visible = (t) => {
            const triggerType = t.getAttribute('data-scroll-trigger')
            if(!t.classList.contains('-visible')){

                if(triggerType == 'js-scroll-car'){
                    

                    anime({
                        targets: t,
                        opacity:[0,1],
                        translateY:['-20%',0],
                        translateX:['20%',0],
                        easing: 'easeOutExpo',
                        duration: 1000,
                        complete :()=>{
                            t.classList.add('-visible')
                        }
                    });

                }else if(triggerType == 'js-scroll-fishes'){
                    const fishs = t.querySelectorAll('.fish');

                    anime({
                        targets: fishs,
                        scale:[.8,1],
                        opacity:[0,1],
                        easing: 'easeOutQuart',
                        duration:function(el, i) { return anime.random(400,800) },
                        delay: function(el, i) { return i * 20 },
                        complete :()=>{
                            t.classList.add('-visible')
                        }

                    });
                }else if(triggerType == 'js-scroll-index-feature'){
                    
                    const timeline = anime.timeline()
                    timeline
                    .add({
                        targets: '.p-index-featrue__img--svgs.-place1 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                        complete :()=>{
                            t.classList.add('-visible')
                        }
                    })
                    .add({
                        targets: '.p-index-featrue__img--svgs.-place2 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs.-place3 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs-sub.-sub1 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=1200")
                    .add({
                        targets: '.p-index-featrue__img--svgs-sub.-sub2 svg',
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs.-coral svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs.-fish1 svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs.-fish2 svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-featrue__img--svgs.-fish3 svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                }else if(triggerType == 'js-scroll-index-lineup'){
                    
                    const timeline = anime.timeline()
                    timeline
                    .add({
                        targets: '.p-index-lineup__img--svgs.-place1 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                        complete :()=>{
                            t.classList.add('-visible')
                        }
                    })
                    .add({
                        targets: '.p-index-lineup__img--svgs.-place2 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-lineup__img--svgs.-place3 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-lineup__img--svgs.-fish1 svg',
                        scale:[0.7,1],
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=1200")
                    .add({
                        targets: '.p-index-lineup__img--svgs.-coral svg',
                        opacity:[0,1],
                        easing: 'easeOutExpo',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-lineup__img--svgs.-fish2 svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                    .add({
                        targets: '.p-index-lineup__img--svgs.-turtle svg',
                        opacity:[0,1],
                        easing: 'easeOutSine',
                        duration:1000,
                    },"-=800")
                }else{
                    t.classList.add('-visible')
                }
            }

        }
    }

    headerInview(){
        const callback = (entries) => {
            entries.forEach( (entry) => {
                const ratio = Math.round(entry.intersectionRatio*100)
                const header = document.querySelector("#l-header")
                if(ratio > 0){
                    if (entry.isIntersecting) {
                        header.classList.add("-in-white")
                    }else{
                        header.classList.remove("-in-white")
                    }
                }else{
                    if (entry.isIntersecting) {
                        header.classList.add("-in-white")
                    }else{
                        header.classList.remove("-in-white")
                    }
                }
            });
        }
        const observerOptions = {
            rootMargin: "0px 0px 0px 0px",
            threshold: [.36,1]
        }
        this.observer = new IntersectionObserver(callback, observerOptions)
        const sections = document.querySelectorAll('[data-header-toggle-footer]')
        if( sections.length > 0 ){
            sections.forEach( el => { 
                this.observer.observe(el)
            })
        }

    }

}