
import barba from '@barba/core';
import gsap from "gsap";


export default class{

	constructor(){

        barba.hooks.once((data) => { 
            this.run()
        })
        barba.hooks.enter((data) => {
            this.run()
        })
    }

    run(){
        const scrollRotation = document.querySelector(".l-floating__text")
        if(scrollRotation){
            this.rotation = 0
            this.animation = gsap.to(
                scrollRotation,
                {
                    duration: 10,
                    ease: "none",
                    rotation:360,
                    repeat: -1
                }
            );

            scrollRotation.addEventListener('mouseenter',()=>{
                this.animation.kill()
                this.rotation = gsap.getProperty(scrollRotation,"rotation")
                if(this.rotation > 360){
                    this.rotation =  this.rotation - 360
                }
                this.animation = gsap.fromTo(
                    scrollRotation,
                    {
                        rotation:this.rotation
                    },
                    {
                        duration: 5,
                        ease: "none",
                        rotation: this.rotation + 360,
                        repeat: -1
                    }
                );
            })

            scrollRotation.addEventListener('mouseleave',()=>{
                this.animation.kill()
                this.rotation = gsap.getProperty(scrollRotation,"rotation")
                if(this.rotation > 360){
                    this.rotation =  this.rotation - 360
                }
                this.animation = gsap.fromTo(
                    scrollRotation,
                    {
                        rotation:this.rotation
                    },
                    {
                        duration: 10,
                        ease: "none",
                        rotation: this.rotation + 360,
                        repeat: -1
                    }
                );
            })
        }

    }
}